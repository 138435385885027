import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {AddCredit, Credit, Currency, DeleteCredit, Organisation} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {uuid} from "../../../common/utils";
import {Handler} from "../../../common/handler";
import {View} from "../../../common/view";
import {Observable} from "rxjs";
import {openConfirmationModalWithCallback} from "../../../app-utils";
import {ModalConfirmAutofocus, ModalConfirmAutofocusData} from "../../../common/modal-confirm/modal-confirm.component";
import {openModal} from "../../../common/app-common-utils";

@Component({
  selector: 'app-credit-details-modal',
  templateUrl: './credit-details-modal.component.html',
  styleUrls: ['./credit-details-modal.component.scss']
})
@Handler()
export class CreditDetailsModalComponent extends View implements OnInit {
  data: CreditDetailsModalComponentData;
  isNewRecord: boolean;
  organisationId: string;
  currencies: Observable<Currency[]>;

  ngOnInit() {
    this.isNewRecord = !this.data.credit?.creditId;
    this.data.credit = this.data.credit || this.defaultRecord();
    this.currencies = this.sendQuery("getCurrencies");
    this.subscribeTo("getOrganisation").subscribe((o: Organisation) => this.organisationId = o.organisationId);
  }

  updateInfo = () => this.sendCommand("host.flux.service.invoicing.api.AddCredit", <AddCredit>{
    creditId: this.data.credit.creditId,
    details: this.data.credit.details,
    organisationId: this.organisationId
  });

  defaultRecord = (): Credit => ({
    creditId: uuid(),
    details: {
      period: {}
    }
  });

  deleteCredit = () => openConfirmationModalWithCallback(confirmed => {
    if (confirmed) {
      this.sendCommand("host.flux.service.invoicing.api.DeleteCredit", <DeleteCredit>{
        creditId: this.data.credit.creditId
      });
    } else {
      openModal(CreditDetailsModalComponent, this.data);
    }
  }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
    type: "danger",
    title: "Delete credit",
    innerHtmlMessage: `<p>Are you sure you want to delete the credit <b>${this.data.credit.details.description}</b>?</p>`,
    confirmText: "Delete credit",
    cancelText: "Cancel"
  }, 'static');
}

export interface CreditDetailsModalComponentData {
  credit: Credit;
  viewContainerRef: ViewContainerRef;
}
