export const environment = {
  production: true,
  apiProtocol: "https://",
  apiDomain: "api.dashboard.flux.host",
  auth: {
    domain: "https://flux-host-dev-cv7hru.us1.zitadel.cloud",
    clientId: "285548239137081988",
    redirectBaseUri: "https://dashboard.flux.host"
  }
};
